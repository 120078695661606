import { startCase, upperCase } from 'lodash';

export const breadcrumbs = (path, param) => {
  const bre = param.reduce((acc, item) => {
    return [
      ...acc,
      {
        text: item,
      },
    ];
  }, []);
  return [
    {
      to: '/dashboard',
      text: localStorage.getItem('zq-space-name'),
    },
    {
      to: `/${path}`,
      text: startCase(path),
    },
    ...bre,
  ];
};

export const breadcrumbDefault = (path) => {
  const breadPath = path.split('/').splice(1).filter((element, index) => element !== '' && index < path.length - 1);

  return breadPath.reduce(
    (acc, item) => {
      return [
        ...acc,
        {
          to: `/${item}`,
          text: path === '/cms' ? upperCase(item) : startCase(item),
        },
      ];
    },
    [{ to: '/dashboard', text: localStorage.getItem('zq-space-name') }]
  );
};
