<template>
  <div class="product-detail">
    <div class="form-content content">
      <div class="d-flex justify-content-end mb-2">
        <ClNavButton :label="$t('buttons.goBack')" @handler="goBack"/>
      </div>
      <CRow>
        <CCol col="12">
          <CCard class="zq--wizard-card">
            <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
              <div class="d-flex justify-content-between">
                <strong class="title">{{ texts.previewPage.productTab.overview }}</strong>
                <CLink class="card-header-action btn-minimize">
                  <ClCardArrow :is-collapsed="basicIsCollapsed" />
                </CLink>
              </div>
            </CCardHeader>
            <CCollapse :show="basicIsCollapsed" :duration="400">
              <CCardBody>
                <!--   ID  -->
                <ClCardRow :label="texts.previewPage.productTab.idLabel">
                  <template #content>
                    <router-link :to="{name: `PreviewProduct`, params: {id: formData.id} }">
                      {{ formData.id }}
                    </router-link>
                  </template>
                </ClCardRow>
                <!--        Created        -->
                <ClCardRow :label="texts.previewPage.productTab.createdLabel" :content="dateFormate(formData.created)" />
                <!--         Space Name       -->
                <ClCardRow :label="texts.previewPage.productTab.spaceNameLabel" :content="formData.spaceName" />
                <!--         Product Name       -->
                <ClCardRow :label="texts.previewPage.productTab.productNameLabel" :content="formData.name" />
                <!--      Description          -->
                <ClCardRow :label="texts.previewPage.productTab.descriptionLabel" :content="formData.description" />
                <!--      Adjustment Factor        -->
                <ClCardRow :label="texts.previewPage.productTab.adjustmentLabel" :content="formData.adjustmentFactor" />
                <!--       Product Ref Id         -->
                <ClCardRow :label="texts.previewPage.productTab.productRefLabel" :content="formData.productRefId" />
                <!--         Product  Type       -->
                <ClCardRow :label="texts.previewPage.productTab.productTypeLabel" :content="formData.productType" />
                <!--      Product Groups          -->
                <ClCardRow :label="texts.previewPage.productTab.tagsLabel">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.productGroups"
                        :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--        Action Type Adjustment Factors        -->
                <ClCardRow :label="texts.previewPage.productTab.actionAdjustmentLabel">
                  <template #content>
                    <ul class="zq--list" v-if="showCAdjustmentFactorsInputs">
                      <li
                        v-for="(meta, index) in formData.actionTypeAdjustmentFactors"
                        class="product-metadata--item"
                        :key="index"
                      >
                        <span> {{ meta.actionType.name }}:  </span><span>{{ meta.adjustmentFactor }}</span>
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--        METADATA        -->
                <ClCardRow :label="texts.previewPage.productTab.metadataLabel">
                  <template #content>
                    <ul class="zq--list">
                      <li
                        v-for="(meta, index) in formData.metadata"
                        class="product-metadata--item"
                        :key="index"
                      >
                        <span> {{ meta.key }}: </span><span>{{ meta.value }}</span>
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
              </CCardBody>
              <!--      Spinner        -->
              <PreviewSpiner v-if="loading" />
            </CCollapse>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {pageConfig} from '@/config';
import PreviewSpiner from "@/shared/UI/Spiner";
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import {dateFormate} from '@/utils/dateFormate';
import {eventsTexts} from "@/config/pageTexts/events.json";
import ClNavButton from "@/shared/components/formComponents/ClNavButton";

export default {
  name: 'ProductDetails',
  components: {
    PreviewSpiner,
    ClCardRow,
    ClCardArrow,
    ClNavButton,
  },
  data() {
    return {
      formData: {
        id: '',
        name: '',
        memberRefId: '',
        groups: [],
        metadata: [{key: '', value: ''}],
        created: '',
      },
      texts: {
        ...eventsTexts
      },
      showCAdjustmentFactorsInputs: false,
      basicIsCollapsed: true,
      inboxIsCollapsed: false,
      deleteModal: false,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  computed: {
    ...mapGetters('events', ['event']),
    ...mapGetters('products', ['success', 'message', 'loading', 'product']),
    ...mapGetters('actionTypes', ['success', 'message', 'actionType']),
  },
  methods: {
    ...mapActions('products', ['handleGetProducts_item']),
    dateFormate,
    async goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    event: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.handleGetProducts_item([val.entityId], 1, 0 );
        }
      },
    },
    product: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.formData = val;
        }
      },
    },
    actionType(val) {
      if (val) {
        const types = val.reduce((acc, item) => {
          if (item) {
            this.showCAdjustmentFactorsInputs = true;
            return [
              ...acc,
              {
                id: item.id,
                name: item.name
              }
            ]
          }
        }, []);
        if (this.formData.actionTypeAdjustmentFactors && types.length) {
          this.formData.actionTypeAdjustmentFactors.map((item) => {
            item.actionType = types.find(data => data.id === item.actionTypeId);
          });
        }
      }
    },
  }
}

</script>
<style lang="scss">
.product-detail {
  height: 100%;
  flex: 2;

  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }

  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);

    .group {
      background: #2eb85c;
      color: #ffffff;
      border-radius: 4px;
    }

  }

}

.product-metadata--list {
  padding: 0;
}

.product-metadata--item {
  span {
    //font-weight: bolder;
  }
}

.detail-label {
  @media (min-width: 420px) {
    max-width: 220px;
  }
}


.overview-label {
  font-style: italic;
  color: darkgray;
}
</style>