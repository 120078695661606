import store from '@/store';
import { breadcrumbs, breadcrumbDefault } from './utils/breadcrumbs';
import {
  fileRepositoryBreadcrumb,
  webAssetsBreadcrumb,
  settingsBreadcrumb,
  contestUpdateBreadcrumbs,
  messagesBreadcrumb
} from './breadcrumbItems';
//import messagesBreadcrumb from "@/router/breadcrumb/breadcrumbItems/messagesBreadcrumb";

/**
 * Forms bread crumbs based on url path and additional parameters
 * @param  router - vue router for forming breadcrumbs by default
 * @param  params - Object with parameters for the formation of specific bread crumbs
 */
const routerBreadcrumbs = (router, params = {}) => {
  let breadcrumb;
  switch (router.name) {
    // Members
    case 'PreviewMember':
    case 'EditMember' : {
      breadcrumb = breadcrumbs('members', [params.name])
      break;
    }
    // Products
    case 'PreviewProduct':
    case 'EditProduct': {
      breadcrumb = breadcrumbs('products', [params.name])
      break
    }
    //Rewards
    case 'CreateReward':
    case 'PreviewReward':
    case 'EditReward': {
      breadcrumb = breadcrumbs('rewards', [params.name])
      break
    }
    // Awards
    case 'PreviewAward': {
      breadcrumb = breadcrumbs('awards', [params.id])
      break
    }
    // Events
    case 'PreviewEvent': {
      breadcrumb = breadcrumbs('events', [params.name])
      break
    }
    // instant WINS
    case 'PreviewInstantWin' :
    case 'EditInstantWin' : {
      breadcrumb = breadcrumbs('instant-wins', [params.name])
      break;
    }
    // Achievements
    case 'PreviewAchievement':
    case 'EditAchievement': {
      breadcrumb = breadcrumbs('achievements', [params.name])
      break
    }
    // Achievements WIDGET
    case 'PreviewAchievementWidget':
    case 'EditWidget': {
      breadcrumb = breadcrumbs('achievements', [params.name])
      break
    }
    // Competition
    case 'PreviewCompetition':
    case 'EditCompetition': {
      breadcrumb = breadcrumbs('competitions', [params.name])
      break
    }

    case 'CreateContest':
    case 'PreviewContest':
    case 'LeaderboardDetail':
    case 'EditContest': {
      breadcrumb = contestUpdateBreadcrumbs([params.competitionId, params.competitionName, params.name, params.spaceName])
      break
    }
    //Action Types
    case 'CreateActionType': {
      breadcrumb = settingsBreadcrumb('action-types', [router.meta.label], '/settings/action-types', 'Action Types')
      break
    }
    case 'PreviewActionType':
    case 'EditActionType': {
      breadcrumb = settingsBreadcrumb('action-types', [params.name], '/settings/action-types', 'Action Types')
      break
    }
    //Reward Types
    case 'CreateRewardType': {
      breadcrumb = settingsBreadcrumb('languages', [router.meta.label], '/settings/reward-types', 'Reward Types')
      break
    }
    case 'PreviewRewardType':
    case 'EditRewardType': {
      breadcrumb = settingsBreadcrumb('reward-types', [params.name], '/settings/reward-types', 'Reward Types')
      break
    }
    //Languages
    case 'EditLanguage':
    case 'PreviewLanguage': {
      breadcrumb = settingsBreadcrumb('languages', [params.name], '/settings/languages', 'Languages')
      break
    }
    case 'CreateLanguage': {
      breadcrumb = settingsBreadcrumb('languages', [router.meta.label], '/settings/languages', 'Languages')
      break
    }

    //Custom Fields
    case 'CreateCustomField': {
      breadcrumb = settingsBreadcrumb('custom-fields', [router.meta.label], '/settings/custom-fields', 'CustomFields')
      break
    }
    case 'PreviewCustomField':
    case 'EditCustomField': {
      breadcrumb = settingsBreadcrumb('custom-fields', [params.name], '/settings/custom-fields', 'CustomFields')
      break
    }

    //Units Of Measure
    case 'CreateUnitOfMeasure': {
      breadcrumb = settingsBreadcrumb('units-of-measure', [router.meta.label], '/settings/units-of-measure', 'UnitsOfMeasure')
      break
    }
    case 'PreviewUnitOfMeasure':
    case 'EditUnitOfMeasure': {
      breadcrumb = settingsBreadcrumb('units-of-measure', [params.name], '/settings/units-of-measure', 'UnitsOfMeasure')
      break
    }

    //Webhooks
    case 'CreateWebhook': {
      breadcrumb = settingsBreadcrumb('webhooks', [router.meta.label], '/settings/webhooks', 'Webhooks')
      break
    }
    case 'PreviewWebhook':
    case 'EditWebhook': {
      breadcrumb = settingsBreadcrumb('webhooks', [router.params.name], '/settings/webhooks', 'Webhooks')
      break
    }

    //Connections
    case 'CreateConnection': {
      breadcrumb = settingsBreadcrumb('connections', [router.params.id], '/settings/connections', 'Connections')
      break
    }
    case 'PreviewConnection':
    case 'EditConnection': {
      breadcrumb = settingsBreadcrumb('connections', [params.name], '/settings/connections', 'Connections')
      break
    }
    case 'PreviewSqsConnection':
    case 'PreviewRabbitMqConnection':
    case 'PreviewKafkaConnection':
    case 'EditSqsConnection':
    case 'EditRabbitMqConnection':
    case 'EditKafkaConnection': {
      breadcrumb = settingsBreadcrumb('connections', [params.name], '/settings/connections', 'Connections')
      break
    }
    case 'CreateSqsConnection':
    case 'CreateKafkaConnection':
    case 'CreateRabbitMqConnection': {
      breadcrumb = settingsBreadcrumb('connections', [router.meta.label], '/settings/connections', 'Connections')
      break
    }

    //Units Of Measure
    case 'CreateComputeEngine': {
      breadcrumb = settingsBreadcrumb('compute-engines', [router.meta.label], '/settings/compute-engines', 'ComputeEngines')
      break
    }
    case 'PreviewComputeEngine':
    case 'EditComputeEngine': {
      breadcrumb = settingsBreadcrumb('compute-engines', [params.name], '/settings/compute-engines', 'ComputeEngines')
      break
    }

    //Messages
    case 'PreviewMemberMessage':
    case 'EditMemberMessage':
    {
      breadcrumb = messagesBreadcrumb('inbox', [router.params.id], '/inbox', 'Member Messages')
      break
    }
    case 'PreviewNotification':
    case 'EditNotificationMessage':
    {
      breadcrumb = messagesBreadcrumb('inbox', [router.params.id], '/inbox', 'Notification Messages')
      break
    }
    case 'CreateMemberMessage':
    case 'CreateNotification':
    case 'CreateInboxMessage':
    {
      breadcrumb = messagesBreadcrumb('inbox', [router.meta.label], '/inbox', 'Create Message')
      break
    }
    //Transformers
    case 'CreateTransformer': {
      breadcrumb = settingsBreadcrumb('transformers', [router.meta.label], '/settings/transformers', 'Transformers')
      break
    }
    case 'PreviewTransformer':
    case 'EditTransformer': {
      breadcrumb = settingsBreadcrumb('transformers', [params.name], '/settings/transformers', 'Transformers')
      break
    }

    //API keys
    case 'CreateApiKey': {
      breadcrumb = settingsBreadcrumb('api-keys', [router.meta.label], '/settings/api-keys', 'ApiKeys')
      break
    }
    case 'PreviewApiKey':
    case 'EditApiKey': {
      breadcrumb = settingsBreadcrumb('api-keys', [router.params.id], '/settings/api-keys', 'ApiKeys')
      break
    }

    //Collaborators
    case 'CreateCollaborator': {
      breadcrumb = settingsBreadcrumb('collaborators', [router.meta.label], '/settings/collaborators', 'Collaborators')
      break
    }
    case 'PreviewCollaborator':
    case 'EditCollaborator': {
      breadcrumb = settingsBreadcrumb('collaborators', [router.params.id], '/settings/collaborators', 'Collaborators')
      break
    }

    //Tags
    case 'CreateTag': {
      breadcrumb = settingsBreadcrumb('connections', [router.meta.label], '/settings/tags', 'Tags')
      break
    }
    case 'PreviewTag':
    case 'EditTag': {
      breadcrumb = settingsBreadcrumb('tags', [params.name], '/settings/tags', 'Tags')
      break
    }
    // WEB ASSETS
    case 'CreateFileRepository' : {
      breadcrumb = webAssetsBreadcrumb('cms', router.name)
      break;
    }

    // Missions
    case 'PreviewMission': {
      breadcrumb = breadcrumbs('missions', [params.name])
      break;
    }

    case 'CreateFolder' : {
      const path = `/cms/preview-repository/${router.params.id}/${router.params.name}`;
      breadcrumb = fileRepositoryBreadcrumb('cms', [router.params.name], path, router.params.name)
      break;
    }

    case 'PreviewFileRepository':
    case 'EditFileRepository' : {
      let pathToRepo = `/cms/preview-repository/${params.id}/${params.name}`
      let pathToFolder = null
      if (router.name === 'PreviewFileRepository') {
        pathToRepo = `/cms/preview-repository/${params.id}`
        pathToFolder = `/cms/preview-repository/${params.id}?item=${params.folderId}`
      }
      breadcrumb = fileRepositoryBreadcrumb(
        'cms',
        [],
        pathToRepo,
        params.name,
        params.folderName,
        pathToFolder,
        params.innerFolders ?? [],
        params.fileName
      )
      break;
    }
    case 'PreviewFile':
    case 'EditFile':
    case 'WebBuilder' :
    case 'UpdateFile' :
    case 'UpdateFolder' :
    case 'FileOverview' : {
      const pathToRepo = `/cms/preview-repository/${router.params.id}`;
      const pathToFolder = `/cms/preview-repository/${router.params.id}?item=${router.params.folderId}`
      breadcrumb = fileRepositoryBreadcrumb(
          'cms',
          [params.fileName],
          pathToRepo,
          params.repositoryName,
          params.folderName,
          pathToFolder,
          params.innerFolders ?? [],
          params.fileName
      )
      break;
    }

    default: {
      breadcrumb = breadcrumbDefault(router.path)
    }
  }

  const toObject = {
    ...router,
    breadcrumb
  }
  // Add bread crumbs to the store
  store.dispatch('path/handlePath', toObject);
}


export default routerBreadcrumbs;