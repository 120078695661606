export default function settingsBreadcrumb(path, param, toPath, text) {
  const bre = param.reduce((acc, item) => {
    return [
      ...acc,
      {
        text: item,
      },
    ];
  }, []);
  return [
    {
      to: '/dashboard',
      text: localStorage.getItem('zq-space-name'),
    },
    {
      to: '/settings',
      text: 'Settings',
    },
    {
      to: toPath,
      text: text,
    },
    ...bre,
  ];
}
