<template>
  <CButton
      :disabled="loading"
      :color="colorBtn"
      @click="handler"
      class="mb-2 common-buttons zq--custom-button zq--custom-button--nav"
      variant="outline">
    <!--  PREV  -->
    <div v-if="btnType === 'prev'" class="zq-nav-button-icon">
      <CIcon name="cil-caret-left" v-if="theme === 'default'"/>

      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fill-rule="evenodd">
          <g fill="#282F37" fill-rule="nonzero">
            <g>
              <g>
                <path
                    d="M5 7.966c.007-.225.094-.44.243-.602l4.236-4.582c.338-.366.898-.377 1.25-.026.35.35.362.932.024 1.297L7.107 8l3.646 3.947c.338.365.327.946-.025 1.297-.351.351-.911.34-1.25-.026L5.244 8.636c-.138-.15-.221-.343-.24-.547L5 7.966z"
                    transform="translate(-906 -140) translate(894 128) translate(12 12)"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <!-- LABEL -->
    <span class="zq--custom-button--nav-label">{{ label }}</span>
    <!-- NEXT -->
    <div class="zq-nav-button-icon zq-nav-button-icon__next" v-if="btnType === 'next'">
      <CIcon name="cil-caret-right" v-if="theme === 'default'"/>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fill-rule="evenodd">
          <g fill="#282F37" fill-rule="nonzero">
            <g>
              <g>
                <path
                    d="M8.034 11c.225-.008.44-.094.602-.243l4.582-4.237c.366-.337.377-.897.026-1.249-.35-.351-.932-.363-1.297-.025L8 8.892 4.053 5.246c-.365-.338-.946-.326-1.297.025-.351.352-.34.912.026 1.25l4.582 4.236c.18.164.422.252.67.242z"
                    transform="translate(-1092 -140) translate(1029 128) translate(63 12) rotate(-90 8 8)"/>
              </g>
            </g>
          </g>
        </g>
      </svg>

    </div>

  </CButton>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    loading: Boolean,
    label: String,
    btnType: {
      type: String,
      default: 'prev'
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default' : {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    }
  },
  methods: {
    handler() {
      this.$emit('handler')
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.common-buttons.zq--custom-button.zq--custom-button--nav {
  display: flex;
}

.zq--custom-button--nav {


  &-label {
    @include media-breakpoint-down('576px') {
      display: none;
    }
  }

  .zq-nav-button-icon {
    display: flex;
    margin-right: 0.3rem;

    &__next {
      margin-right: 0;
      margin-left: 0.3rem;
    }

    @include media-breakpoint-down('576px') {
      margin: 0;
    }
  }

  &:hover svg g {
    fill: #FFFFFF;
  }
}

.zq--custom-button.zq--custom-button--nav {
  @include media-breakpoint-down('576px') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    padding: 0;

  }
}
</style>