export default function fileRepositoryBreadcrumb(
  path,
  param,
  pathToRepo,
  repoName,
  folderName,
  pathToFolder,
  innerFolders
) {

    const bre = param.reduce((acc, item) => {
        return [
            ...acc,
            {
                text: item
            },
        ]
    }, [])

    let breadcrumb = [
        {
            to: '/',
            text: localStorage.getItem('zq-space-name')
        },
        {
            to: '/cms',
            text: 'CMS'
        },

        {
            to: pathToRepo,
            text: repoName
        },
        ...bre
    ]

    if (folderName) {
        breadcrumb.splice(3, 0, {to: pathToFolder, text: folderName})
    }

    if (innerFolders && innerFolders.length) {
        const innerFoldersBreadcrumbs = [];
        innerFolders.forEach(i => {
            innerFoldersBreadcrumbs.push({
                to: pathToRepo + '?item=' + i.id,
                text: i.name
            });
        })
        if (innerFoldersBreadcrumbs.length) {
            breadcrumb.splice(3, 0, ...innerFoldersBreadcrumbs)
        }

    }

    return breadcrumb
}