import { startCase, upperCase } from 'lodash';

export default function webAssetsBreadcrumb(path, param){
    return [
        {
            to: '/',
            text: 'Home'
        },
        {
            to: `/${path}`,
            text: param !== 'CreateFileRepository' ? startCase(path) : upperCase(path)
        },
        {
            text: param !== 'CreateFileRepository' ? param : 'Create Repository'
        }
    ]
}