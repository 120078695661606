<template>
  <div class="zq-card-row">
    <div class="zq-card-row--label">
      <div class="overview-label">{{ label }}:</div>
    </div>
    <div class="zq-card-row--content" ref="contentRef">
      <span
        v-if="content && !html"
        class="zq-card-row--content__text"
        :class="{ 'zq-card-row--content__bold': bold }"
        v-html="content"
      ></span>
      <span
        v-if="content && html"
        class="zq-card-row--content__text"
        :class="{ 'zq-card-row--content__bold': bold }"
        v-html="content"
      ></span>
      <slot name="content"></slot>
      <slot />
      <i
        v-if="label.toLowerCase() === 'id'"
        class="fa fa-copy zq-card-row--copy-icon"
        @click="handleCopy"
      ></i>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from '@/utils/textUtils';

export default {
  props: {
    label: String,
    content: [String, Number, Boolean],
    html: Boolean,
    bold: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCopy() {
      copyToClipboard(this.$refs.contentRef);
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.zq-card-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  min-height: 32px;

  .zq-card-row--content {
    font-size: 13px;
    color: #282f37;
    min-width: 60px;
    max-width: 600px;
    flex: 1;
    margin-bottom: 0.5rem;
    word-break: break-word;
    margin-left: 0.5rem;
    .zq--list {
      .group {
        color: #ffffff;
        border-radius: 12px;
      }
    }
    //.zq-card-row--content__text.zq-card-row--content__bold {
    //  font-weight: bold;
    //}

    @include media-breakpoint-up("1200px") {
      max-width: 900px;
    }
  }

  .zq-card-row--copy-icon {
    margin-left: 0.5rem;
    cursor: copy;
  }
}

.zq-card-row--label {
  min-width: 186px;

  .overview-label {
    margin-bottom: 0.5rem;
    font-style: italic;
    color: darkgray;
  }
}
</style>