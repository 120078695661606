<template>
  <div>
    <CIcon
        v-if="theme === 'default'"
        :name="`cil-chevron-${
                        isCollapsed ? 'bottom' : 'top'
                      }`"
        class="icon p-1"
    />
    <i v-else :class="`fa fa-chevron-${!isCollapsed ? 'down' : 'up'}`"></i>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    isCollapsed: Boolean
  },
  computed: {
    ...mapGetters('theme', ['theme']),

  },
}
</script>