<template>
  <div class="preview-event">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ formData.id }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" :active-tab="activeTab" @update:activeTab="setActiveTab">
      <CTab :title="texts.previewPage.title">
        <EventDetails />
      </CTab>
      <CTab :title="texts.previewPage.memberTabTitle">
        <MemberDetails />
      </CTab>
      <CTab :title="texts.previewPage.productTabTitle">
        <ProductDetails />
      </CTab>
      <CTab :title="texts.previewPage.actionTabTitle">
        <ActionDetails />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateFormate } from '@/utils/dateFormate';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import MemberDetails from './details/MemberDetails';
import ProductDetails from './details/ProductDetails';
import EventDetails from './details/EventDetails';
import ActionDetails from './details/ActionDetails';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { events } from '@/config/descriptions/events';
import { eventsTexts } from '@/config/pageTexts/events.json';

export default {
  components: {
    IconWithTooltip,
    MemberDetails,
    ProductDetails,
    ActionDetails,
    EventDetails,
  },
  data() {
    return {
      texts: {
        ...eventsTexts
      },
      formData: {
        id: '',
        created: '',
        spaceName: '',
        memberId: '',
        memberRefId: '',
        action: '',
        entityRefId: '',
        unitOfMeasure: '',
        sourceValue: '',
        points: '',
        transactionTimestamp: '',
        metadata: []
      },
      basicIsCollapsed: true,
      inboxIsCollapsed: false,
      showModal: false,
      activeTab: 0,
      formattedMetadata: '',
    }
  },
  computed: {
    ...mapGetters('events', ['loading', 'message', 'event']),
    ...mapGetters('spaces', ['spaces']),
    descriptions() {
      return {
        ...events.preview
      }
    }
  },
  watch: {
    event: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.formData = val;
          if (this.formData.metadata) {
            this.formatMetaData(this.formData.metadata);
          }
          routerBreadcrumbs(this.$router.currentRoute, {
            id: val.id
          });
        }
      },
    },
  },
  methods: {
    formatMetaData(metadata) {
      this.formattedMetadata = JSON.stringify(metadata);
      this.formattedMetadata = this.formattedMetadata.replaceAll('{', '');
      this.formattedMetadata = this.formattedMetadata.replaceAll('}', '');
      this.formattedMetadata = this.formattedMetadata.replaceAll(',', ', ');
      this.formattedMetadata = this.formattedMetadata.replaceAll(':', ': ');
    },
    dateFormate(val) {
      return dateFormate(val)
    },
    closeModal() {
      this.showModal = false
    },
    async goBack() {
      this.$router.go(-1);
    },
    setActiveTab(val) {
      this.activeTab = val;
    }
  },
};
</script>

<style lang="scss">
.preview-event {
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }
  .form-content {
    height: 100%;
    background: var(--zq-sub-bg);
  }
  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }
  .events-metadata--list {
    padding: 0;
  }
  .overview-label {
    font-style: italic;
    color: darkgray;
  }
}
</style>