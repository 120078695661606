<template>
  <div class="d-flex justify-content-between align-items-center previewSpiner">
    <CSpinner grow  />
    <div class="previewSpinnerLabel" v-if="texts.length">{{ text }}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      text: '',
    }
  },
  props: {
    texts: {
      type: Array,
      default () {
        return []
      }
    },
    delay: {
      type: Number,
      default: 1000
    },
  },
  mounted() {
    if (this.texts.length) {
      this.texts.forEach((t, i) => {
        setTimeout(() => {this.text = t;}, i * this.delay);
      })
    }
  }
}
</script>

<style>
.previewSpiner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 50%;
}
.previewSpinnerLabel {
  position: absolute;
  transform: translate(calc(-50% + 1rem), 50px);
}
</style>
