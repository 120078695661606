<template>
  <div class="action-detail">
    <div class="form-content content">
      <div class="d-flex justify-content-end mb-2">
        <ClNavButton :label="$t('buttons.goBack')" @handler="goBack"/>
      </div>
      <CRow>
        <CCol col="12">
          <CCard class="zq--wizard-card">
            <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
              <div class="d-flex justify-content-between">
                <strong class="title">{{ texts.previewPage.actionTab.overview }}</strong>
                <CLink class="card-header-action btn-minimize">
                  <ClCardArrow :is-collapsed="basicIsCollapsed" />
                </CLink>
              </div>
            </CCardHeader>
            <CCollapse :show="basicIsCollapsed" :duration="400">
              <CCardBody>
                <!--ID -->
                <ClCardRow :label="texts.previewPage.actionTab.idLabel">
                  <template #content>
                    <router-link :to="{name: `PreviewActionType`, params: {id: formData.id, name: formData.name} }">
                      {{ formData.id }}
                    </router-link>
                  </template>
                </ClCardRow>
                <!-- Created -->
                <ClCardRow :label="texts.previewPage.actionTab.createdLabel" :content="dateFormate(formData.created)" />
                <!-- Space Name -->
                <ClCardRow :label="texts.previewPage.actionTab.spaceNameLabel" :content="formData.spaceName" />
                <!--         KEY       -->
                <ClCardRow :label="texts.previewPage.actionTab.keyLabel" :content="formData.key" />
                <!--         actionType Name       -->
                <ClCardRow :label="texts.previewPage.actionTab.actionTypeLabel" :content="formData.name" />
                <!-- Description -->
                <ClCardRow :label="texts.previewPage.actionTab.descriptionLabel" :content="formData.description" />
                <!--      actionType Groups          -->
                <ClCardRow :label="texts.previewPage.actionTab.tagsLabel">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.tags"
                        :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--       System         -->
                <ClCardRow
                  v-if="formData.constraints"
                  :label="texts.previewPage.actionTab.systemLabel"
                  :content="`${formData.constraints.includes('system') ? 'Yes' : 'No'}`"
                />
                <!--          Unit Of Measure        -->
                <ClCardRow :label="texts.previewPage.actionTab.unitOfMeasureLabel" :content="formData.unitOfMeasureType" />
                <!--        METADATA                -->
                <ClCardRow :label="texts.previewPage.actionTab.metadataLabel">
                  <template #content>
                    {{ metadata }}
                  </template>
                </ClCardRow>
              </CCardBody>
              <!--      Spinner        -->
              <PreviewSpiner v-if="loading" />
            </CCollapse>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import PreviewSpiner from "@/shared/UI/Spiner";
import {dateFormate} from '@/utils/dateFormate';
import {mapActions, mapGetters} from "vuex";
import {eventsTexts} from "@/config/pageTexts/events.json";
import ClNavButton from "@/shared/components/formComponents/ClNavButton";

export default {
  name: "ActionDetails",
  components: {
    ClCardRow,
    ClCardArrow,
    PreviewSpiner,
    ClNavButton,
  },
  data() {
    return {
      formData: {
        name: '',
        id: '',
        created: '',
        unitOfMeasureType: '',
        key: '',
        description: '',
        system: null,
        metadata: [],
        groups: [],
      },
      texts: {
        ...eventsTexts
      },
      metadata: '',
      basicIsCollapsed: true,
    }
  },
  computed: {
    ...mapGetters('events', ['event']),
    ...mapGetters('actionTypes', ['loading', 'actionTypes']),
  },
  methods:{
    ...mapActions('actionTypes', ['handleGetActionTypesByQuery']),
    dateFormate,
    async goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    event: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.handleGetActionTypesByQuery(
            {
              queryRequest: {
                must: [
                  {
                    queryField: 'key',
                    queryValues: [val.action]
                  }
                ]
              }
            }
          );
        }
      },
    },
    actionTypes: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.formData = val[0];
        }
      },
    },
  },
}
</script>

<style scoped>

</style>