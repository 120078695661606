<template>
  <CModal
    :title="title"
    color="danger"
    centered
    :class="className"
    :show.sync="modal"
    :closeOnBackdrop="closeOnBackdrop"
    @update:show="updateModal"
  >
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
      <button type="button" class="close" @click="closeByIcon">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div
      v-if="toolTipText && toolTipText.length"
      class="zq-code-edit--tooltip"
      :style="{
        position: 'absolute',
        right: `calc(100% - ${(title.length * 10 + 85)}px)`,
        marginRight: '20px'
      }"
    >
      <IconWithTooltip class="mt-2" :text="toolTipText"/>
    </div>
    <div class="zq--modal--subtitle" v-if="subTitle">
      <span>{{ subTitle }}</span>
    </div>
    <div class="zq--modal--danger" v-if="dangerText">
      <span>{{ dangerText }}</span>
    </div>
    <div class="zq--modal--message mb-3" v-html="messageGeneral">
    </div>

    <slot name="body"></slot>
    <div slot="footer" class="zq--modal--footer-content">
      <CButton
        v-if="isCloseButton"
        @click="closeModal"
        class="ml-2 zq--custom-button"
        :color="colorBtn('secondary')"
      >
        {{ cancelBtnLabel }}
      </CButton>
      <CButton
        v-if="isSuccessButton"
        @click="doFunction"
        class="ml-3 zq--responsive-button__common"
        :color="colorBtn('danger')"
      >
        {{ successBtnLabel }}
      </CButton>
    </div>
    <slot name="loading" v-if="loading"></slot>
  </CModal>
</template>

<script>
import { mapGetters } from 'vuex';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';

export default {
  name: 'Modal',
  data() {
    return {
      modal: false,
      className: ['zq--modal']
    };
  },
  components: {
    IconWithTooltip,
  },
  computed: {
    ...mapGetters('theme', ['theme']),
  },
  props: {
    title: String,
    toolTipText: String,
    subTitle: String,
    dangerText: String,
    closeBtnClass: String,
    messageGeneral: String,
    modalShow: Boolean,
    closeOnBackdrop: {
      type: Boolean,
      default: true
    },
    cancelBtnLabel: {
      type: String,
      default: 'Close'
    },
    successBtnLabel: {
      type: String,
      default: 'I\'m Sure!'
    },
    classes: {
      type: Array,
      default() {
        return [];
      }
    },
    isSuccessButton: {
      type: Boolean,
      default: true
    },
    isCloseButton: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  created() {
    if (this.classes.length) {
      this.className = [...this.className, ...this.classes];
    }
  },
  methods: {
    doFunction() {
      this.$emit('doFunction');
    },
    closeByIcon() {
      this.$emit('closeModal');
      this.modal = false;
    },
    updateModal(val) {
      this.$emit('toggle-modal', val);
    },
    closeModal() {
      this.$emit('toggle-modal', false);
    },
    colorBtn(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default: {
          return '';
        }
      }
    }
  },
  watch: {
    modalShow(val) {
      this.modal = val;
    },
  },
};
</script>

<style lang="scss">
.zq-code-edit--tooltip {
  position: absolute;
  top: -50px;
}

.zq--modal {
  &--danger {
    margin-bottom: 22px;
    margin-top: -.6rem;

    span {
      color: var(--zq-main-action);
      font-size: 14px;
      font-weight: 600;
    }
  }

  .modal-header {
    .close {
      &:focus {
        outline: none;
      }
    }
  }

  .zq--table-loader-wrapper {
    top: 28px;

    .zq--table-loader-icon {
      top: 200px;
    }
  }
}
</style>
