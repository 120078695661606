<template>
  <div class="preview-member">
    <div class="form-content content">
      <div class="d-flex justify-content-end mb-2">
        <ClNavButton :label="$t('buttons.goBack')" @handler="goBack"/>
      </div>
      <CRow>
        <CCol col="12">
          <CCard class="zq--wizard-card">
            <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
              <div class="d-flex justify-content-between">
                <strong class="title">{{ texts.previewPage.memberTab.overview }}</strong>
                <CLink class="card-header-action btn-minimize">
                  <ClCardArrow :is-collapsed="basicIsCollapsed" />
                </CLink>
              </div>
            </CCardHeader>
            <CCollapse :show="basicIsCollapsed" :duration="400">
              <CCardBody>
                <!--        ID        -->
                <ClCardRow :label="texts.previewPage.memberTab.idLabel">
                  <template #content>
                    <router-link :to="{name: `PreviewMember`, params: {id: formData.id} }">
                      {{ formData.id }}
                    </router-link>
                  </template>
                </ClCardRow>
                <!--       Created       -->
                <ClCardRow :label="texts.previewPage.memberTab.createdLabel" :content="dateFormate(formData.created)" />
                <!--      Space Name        -->
                <ClCardRow :label="texts.previewPage.memberTab.spaceNameLabel" :content="formData.spaceName" />
                <!--        NAME       -->
                <ClCardRow :label="texts.previewPage.memberTab.nameLabel" :content="formData.name" />
                <!--      Member Ref Id      -->
                <ClCardRow :label="texts.previewPage.memberTab.memberRefLabel" :content="formData.memberRefId" />
                <!--        Member Type        -->
                <ClCardRow :label="texts.previewPage.memberTab.memberTypeLabel" :content="formData.memberType" />
                <!--        Tags           -->
                <ClCardRow :label="texts.previewPage.memberTab.tagsLabel">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.groups"
                          :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--  Metadata   -->
                <ClCardRow :label="texts.previewPage.memberTab.metadataLabel">
                  <template #content>
                    <ul class="members-metadata--list">
                      <li
                        v-for="(meta, index) in formData.metadata"
                        class="members-metadata--item"
                        :key="index"
                      >
                        <span> {{ meta.key }}: </span><span>{{ meta.value }}</span>
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
              </CCardBody>
              <PreviewSpiner v-if="loading" />
            </CCollapse>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PreviewSpiner from '@/shared/UI/Spiner';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import {dateFormate} from '@/utils/dateFormate';
import {eventsTexts} from "@/config/pageTexts/events.json";
import ClNavButton from "@/shared/components/formComponents/ClNavButton";

export default {
  name: 'MemberDetails',
  components: {
    PreviewSpiner,
    ClCardRow,
    ClCardArrow,
    ClNavButton,
  },
  data() {
    return {
      formData: {
        id: '',
        name: '',
        memberRefId: '',
        groups: [],
        metadata: [{key: '', value: ''}],
      },
      texts: {
        ...eventsTexts
      },
      editorOption: {
        theme: 'snow',
      },
      options: [],
      basicIsCollapsed: true,
      inboxIsCollapsed: false,
      deleteModal: false,
      itemsPerPage: 10,
      query: {},
      sortBy: [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ],
      page: 1,
    };
  },
  computed: {
    ...mapGetters('events', ['event']),
    ...mapGetters('members', ['success', 'message', 'members', 'loading', 'member']),
  },
  methods: {
    ...mapActions('members', ['handleDeleteMember', 'handleGetMembers_item']),
    dateFormate,
    async goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    member: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
    event: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.handleGetMembers_item([val.memberId],1,0)
            .then(data => {
              routerBreadcrumbs(this.$router.currentRoute, {
                name: data[0].name
              });
            });
        }
      },
    },
  },
};
</script>
<style lang="scss">
.preview-member {
  height: 100%;
  flex: 2;

  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }

  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);

  }

}

.members-metadata--list {
  padding: 0;
}

.members-metadata--item {
  span {
    //font-weight: bolder;
  }
}

.detail-label {
  @media (min-width: 420px) {
    max-width: 220px;
  }
}

.overview-label {
  font-style: italic;
  color: darkgray;
}
</style>