export default function messagesBreadcrumb(path, param ,toPath,text){
    const bre = param.reduce((acc, item) => {
        return [
            ...acc,
            {
                text: item
            },
        ]
    }, [])
    return [
        {
            to: '/',
            text: 'Home'
        },
        {
            to: '/inbox',
            text: 'Inbox'
        },
        {
            to: toPath,
            text: text
        },
        ...bre
    ]
}
