export default function contestUpdateBreadcrumbs(param){
    return [
        {
            to: '/',
            text: param[3]
        },
        {
            to: `/competitions`,
            text: 'Competitions'
        },
        {
            to: `/competitions/detail/${param[0]}`,
            text: `${param[1]}`
        },
        {
            text: param[2]
        }
    ]
}